.animation-bodydragon {
  animation: 1s fadeBodyDragon linear forwards infinite alternate-reverse;
}

@keyframes fadeBodyDragon {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-6px);
  }
}
.animation-headdragon {
  transform-origin: left center;
  animation: 1s fadeHeadDragon linear forwards infinite alternate-reverse;
}

@keyframes fadeHeadDragon {
  0% {
    transform: rotate(4deg);
  }

  100% {
    transform: rotate(15deg);
  }
}

.animation-hindlegs {
  transform-origin: left top;
  animation: 1s hideDragon linear forwards;
}
@keyframes hideDragon {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-20deg) translateY(0px);
  }
  25% {
    transform: rotate(0deg) translateY(0px);
  }
  100% {
    transform: rotate(38deg) translateY(-10px);
  }
}

.animation-frontlegs {
  transform-origin: left top;
  animation: 1s frontDragon ease-in-out forwards;
}
@keyframes frontDragon {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-65deg) translateY(5px);
  }
  100% {
    transform: rotate(-20deg) translateY(5px);
  }
}
.animation-dragon-even {
  transform-origin: left top;
  animation: 1.4s bodyDragonEven linear forwards;
}
@keyframes bodyDragonEven {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-25deg) translateY(5px);
  }
  65% {
    transform: rotate(-15deg) translateX(130px) translateY(-165px);
  }
  80% {
    transform: rotate(-10deg) translateX(135px) translateY(-170px);
  }
  90% {
    transform: rotate(-5deg) translateX(140px) translateY(-175px);
  }
  100% {
    transform: rotate(-5deg) translateX(140px) translateY(-175px);
  }
}
.animation-dragon-odd {
  transform-origin: right top;
  animation: 1.4s bodyDragonOdd linear forwards;
}
@keyframes bodyDragonOdd {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(25deg) translateY(5px);
  }
  65% {
    transform: rotate(15deg) translateX(-130px) translateY(-165px);
  }
  80% {
    transform: rotate(10deg) translateX(-135px) translateY(-170px);
  }
  90% {
    transform: rotate(5deg) translateX(-140px) translateY(-175px);
  }
  100% {
    transform: rotate(5deg) translateX(-140px) translateY(-175px);
  }
}
.background-cloud_number {
  background-image: url(../image/Game/DragonSnake/number.png);
  background-position: center;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}
.all-legs {
  bottom: -16px;
}
/* even */
.front-legs_even {
  right: -12px;
}
.head-even {
  top: -40px;
  right: -36px;
}
/* odd */
.head-odd {
  top: -40px;
  left: -36px;
}
.hind-legs_odd {
  right: -1px;
}
.front-legs_odd {
  left: -12px;
}
.reverse_image {
  transform: scaleX(-1);
}
.animation-scroll_dragon {
  animation: 1s fadeScrollBG linear forwards;
}
@keyframes fadeScrollBG {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(180px);
  }
}
.border-note-dragon {
  background-color: #fff4a3;
}
