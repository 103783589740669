.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  animation: flip 2s linear;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card .flip-card-inner {
  transform: rotateY(720deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
  width: 100%;
  height: 100%;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}
@keyframes flip {
  0% {
    transform: scale(0) rotateY(0);
  }
  100% {
    transform: scale(1) rotateY(720deg);
  }
}
.containerNotify__backgrounds-physical
  .containerNotify__backgrounds-list-physical {
  overflow-y: scroll;
  max-height: 700px;
  padding: 0px 0px;
  border-radius: 12px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.content-physical-popup {
  top: 50%;
  transform: translateY(-50%);
}
.border-buttongame {
  border: 2px solid #caf5ff;
}
/* Thanh cuộn tổng thể */
::-webkit-scrollbar {
  width: 3px;
}

/* Phần track của thanh cuộn */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Phần thumb của thanh cuộn */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Phần thumb của thanh cuộn khi hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
