.bg-house {
  animation: 3s fadeHouse ease-in-out forwards;
}
@keyframes fadeHouse {
  from {
    transform: translateY() scale(1);
  }
  to {
    transform: translateY(-100px) scale(1.5);
  }
}
.animation-fence {
  animation: 2.8s fadeFence ease-in-out forwards;
}
@keyframes fadeFence {
  from {
    transform: translateY() scale(1);
  }
  to {
    transform: translateY(0px) scale(1.4);
  }
}
.animation-ghost {
  animation: 2s fadeGhosts linear infinite alternate-reverse;
}
@keyframes fadeGhosts {
  0% {
    transform: translateX(-25px) translateY(10px);
  }
  30% {
    transform: translateX(5px) translateY(0px);
  }
  55% {
    transform: translateX(10px) translateY(20px);
  }
  75% {
    transform: translateX(20px) translateY(20px);
  }
  100% {
    transform: translateX(40px) translateY(5px);
  }
}
.animation-cat {
  animation: 4s fadeCat linear infinite;
}
@keyframes fadeCat {
  0% {
    transform: translateX(0px) translateY(20px);
  }
  10% {
    transform: translateX(5px) rotate(40deg) translateY(10px);
  }
  100% {
    transform: translateX(150px) rotate(80deg) translateY(90px);
  }
}
.animation-ghost2 {
  animation: 3s fadeGhost2 linear infinite alternate-reverse;
}
@keyframes fadeGhost2 {
  0% {
    transform: translateX(25px) translateY(10px);
  }
  30% {
    transform: translateX(-5px);
  }
  55% {
    transform: translateY(10px);
  }
  75% {
    transform: translateX(-20px) translateY(10px);
  }
  100% {
    transform: translateX(0px);
  }
}
.animation-tornado {
  animation: 5s fadeTornado linear infinite;
}
@keyframes fadeTornado {
  0% {
    transform: rotate(0deg) scale(0);
  }
  100% {
    transform: rotate(-360deg) scale(45);
  }
}
.animation-whiteghost {
  animation: 5s fadeWhiteGhost linear infinite;
}
@keyframes fadeWhiteGhost {
  0% {
    transform: translateX(-100px) translateY(200px);
  }
  55% {
    transform: translateX(-25px) translateY(-100px);
  }
  100% {
    transform: translateX(0px);
  }
}
.animation-whiteghost2 {
  animation: 5s fadeWhiteGhost2 linear infinite;
}
@keyframes fadeWhiteGhost2 {
  0% {
    transform: translateX(100px) translateY(200px);
  }
  55% {
    transform: translateX(25px) translateY(-80px);
  }
  100% {
    transform: translateX(0px) translateY(-100px);
  }
}
.animation-whiteghost3 {
  animation: 5s fadeWhiteGhost3 linear infinite;
}
@keyframes fadeWhiteGhost3 {
  0% {
    transform: translateX(100px) translateY(-500px);
  }
  55% {
    transform: translateX(25px) translateY(-80px);
  }
  100% {
    transform: translateX(0px) translateY(-130px);
  }
}
.animation-whiteghost4 {
  animation: 5s fadeWhiteGhost4 linear infinite;
}
@keyframes fadeWhiteGhost4 {
  0% {
    transform: translateX(-100px) translateY(-400px);
  }
  55% {
    transform: translateX(-25px) translateY(-100px);
  }
  100% {
    transform: translateX(0px) translateY(-300px);
  }
}
.animation-popupghost {
  animation: 1s fadeGhost linear 1 forwards;
}
@keyframes fadeGhost {
  0% {
    transform: translateY(-200px);
  }
  50% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(-20px);
  }
  75% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animation-tornado-result {
  animation: 2s fadeTornadoResult linear infinite;
}
@keyframes fadeTornadoResult {
  from {
    transform: rotate(0) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}
