.tab-receive button {
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  border: 1px solid #5bc2dc;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.tab-receive button:disabled {
  height: 45px;
  border-radius: 10px;
  background-color: #5bc2dc;
  width: 90%;
  font-size: 13px;
  color: white;
  box-shadow: 2px 4px 8px 0px rgba(74, 74, 75, 0.5);
}
.tab-receive {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
  background-color: #ffffff;
  border-top: 1px solid #bdbdbd;
  border-radius: 18px;
  padding-top: 2px;
  padding-bottom: 12px;
}
.tab-receive1 button {
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  border-radius: 10px;
}
.tab-receive1 button:disabled {
  height: 45px;
  border-radius: 10px;
  background-color: #5bc2dc;
  width: 90%;
  font-size: 13px;
  color: white;
  box-shadow: 2px 4px 8px 0px rgba(74, 74, 75, 0.5);
  border: 1px solid #ffffff;
}
.tab-receive1 {
  background-color: #e8faff;
  border: 1px solid #b5e0ee;
  border-radius: 18px;
  padding-top: 2px;
  padding-bottom: 12px;
}
.tab-weekmonth button {
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  border-radius: 20px;
  background-color: #d4d4d4;
  width: 90%;
  height: 100%;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}
.tab-weekmonth button:disabled {
  height: 35px;
  border-radius: 30px;
  background-color: #5bc2dc;
  width: 90%;
  font-size: 13px;
  color: white;
}
.tab-weekmonth {
  background-color: #ffffff;
  border-radius: 50px;
  padding-bottom: 6px;
}

.tab-leaderboard button {
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  border-radius: 10px;
  border: 2px solid #b5e0ee;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 9px;
  padding-right: 9px;
  width: 90%;
}
.tab-leaderboard button:disabled {
  height: 45px;
  border-radius: 10px;
  background-color: #5bc2dc;
  width: 90%;
  font-size: 13px;
  color: white;
  box-shadow: 2px 4px 8px 0px rgba(74, 74, 75, 0.5);
  border: 1px solid #ffffff;
}
.tab-leaderboard {
  background-color: #ffffff;
  border: 1px solid #b5e0ee;
  border-radius: 18px;
  padding-top: 2px;
  padding-bottom: 12px;
}
.border-table-listgift {
  background-color: #bec5d2;
  border-radius: 5px;
  border: 0px solid #ffffff;
  padding: 5px;
  width: 70px;
  text-align: right;
  height: 35px;
}
.border-table-listwhitegift {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  width: 50px;
  text-align: right;
  height: 35px;
}
.overhand {
  display: flex;
  overflow-y: scroll;
  white-space: nowrap;
  /* overflow-x: hidden;  */
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
}
.overhand::-webkit-scrollbar {
  display: none;
}
.border-tab-exchange {
  border: 1px solid #4e9abb;
  border-radius: 100px;
  font-size: 14px;
}
.border-select-day {
  border: 1px solid #65c2e1;
}
