.animation_leg-left {
  animation: 4s fadeAnimation linear infinite forwards alternate-reverse;
}
@keyframes fadeAnimation {
  0% {
    bottom: -18px;
  }
  20% {
    bottom: -10px;
  }
  40% {
    bottom: -18px;
  }
  60% {
    bottom: -10px;
  }
  80% {
    bottom: -18px;
  }
  100% {
    bottom: -10px;
  }
}
.animation_leg-right {
  animation: 4s fadeAnimation linear infinite forwards alternate-reverse;
}
@keyframes fadeAnimation {
  0% {
    bottom: -10px;
  }
  20% {
    bottom: -18px;
  }
  40% {
    bottom: -10px;
  }
  60% {
    bottom: -18px;
  }
  80% {
    bottom: -10px;
  }
  100% {
    bottom: -18px;
  }
}
.animation_body {
  animation: 0.8s fadeBody linear infinite forwards alternate-reverse;
}
@keyframes fadeBody {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.animation_go {
  animation: 3s fadeGo linear forwards 2 alternate;
  will-change: transform;
}
@keyframes fadeGo {
  0% {
    top: 0px;
  }
  100% {
    top: -185px;
  }
}
.animation-box-summer1 {
  animation: 2s linear fadeBoxSummer1 infinite alternate-reverse;
  will-change: transform;
  backface-visibility: hidden;
}
@keyframes fadeBoxSummer1 {
  0% {
    transform: translate3d(0, -7px, 0) rotate(4deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(-3deg);
  }
}
.animation-box-summer2 {
  animation: 1s linear fadeBoxSummer2 infinite alternate-reverse;
  will-change: transform;
  backface-visibility: hidden;
}
@keyframes fadeBoxSummer2 {
  0% {
    transform: translate3d(0, -3px, 0) rotate(-3deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(3deg);
  }
}
.animation-box-summer3 {
  animation: 2.5s linear fadeBoxSummer3 infinite alternate-reverse;
  will-change: transform;
  backface-visibility: hidden;
}
@keyframes fadeBoxSummer3 {
  0% {
    transform: translate3d(0, -6px, 0) rotate(5deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(-8deg);
  }
}
.animation-box-summer4 {
  animation: 1.5s linear fadeBoxSummer4 infinite alternate-reverse;
  will-change: transform;
  backface-visibility: hidden;
}
@keyframes fadeBoxSummer4 {
  0% {
    transform: translate3d(0, -5px, 0) rotate(6deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(-6deg);
  }
}
.animation_go-left {
  animation: 0.3s linear fadeGoLeft forwards;
  will-change: transform;
}
@keyframes fadeGoLeft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100%);
  }
}
.animation_go-right {
  animation: 0.3s linear fadeGoRight forwards;
  will-change: transform;
}
@keyframes fadeGoRight {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100%);
  }
}
.borderpopup_description-summer {
  border: 1px solid #096b9a;
}
.text-border {
  color: #ffeb76;
  text-shadow: -1px -1px 0 #0b77a4, 1px -1px 0 #0b77a4, -1px 1px 0 #0b77a4,
    1px 1px 0 #0b77a4;
}
.animation-takephoto-summer {
  animation: fadeJump 1s linear infinite alternate-reverse;
}
@keyframes fadeJump {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-5px);
  }
}
