.fly1 {
  animation: fly1 1.2s ease-in-out reverse infinite;
}
.rotate1 {
  transform: rotate(-30deg);
}
.rotate2 {
  transform: rotate(0deg);
}
.rotate3 {
  transform: rotate(-10deg);
}
.rotate4 {
  transform: rotate(-20deg);
}
@keyframes fly1 {
  0% {
    transform: translate(0px);
    transform: rotate(-40deg);
  }
  25% {
    transform: translate(40px, 60px);
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(80deg);
    transform: translate(-30px, -90px);
  }
  50% {
    transform: rotate(360deg);
    transform: translate(40px, 30px);
  }
  65% {
    transform: rotate(360deg);
    transform: translate(60px, -70px);
  }
  75% {
    transform: rotate(-360deg);

    transform: translate(30px, 80px);
  }
  85% {
    transform: rotate(-180deg);

    transform: translate(80px, 40px);
  }
  85% {
    transform: rotate(-180deg);

    transform: translate(100px, 80px);
  }
  100% {
    transform: rotate(0deg);

    transform: translate(0px);
  }
}
.fly2 {
  animation: fly2 1.2s ease-in-out infinite;
}
@keyframes fly2 {
  0% {
    transform: translate(0px, 0px) rotate(40deg);
  }
  25% {
    transform: translate(30px, 80px) rotate(60deg);
  }
  50% {
    transform: translate(25px, -10px) rotate(100deg);
  }
  65% {
    transform: translate(-30px, 85px) rotate(30deg);
  }
  75% {
    transform: translate(0px, -105px) rotate(0deg);
  }
  85% {
    transform: translate(-45px, 50px) rotate(180deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(300deg);
  }
}
.fly11 {
  animation: fly11 1.8s ease-out infinite;
}
@keyframes fly11 {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(10px, -180px) rotate(50deg);
  }
  35% {
    transform: translate(15px, -38px) rotate(20deg);
  }
  50% {
    transform: translate(25px, -6px) rotate(-20deg);
  }
  65% {
    transform: translate(60px, -170px) rotate(30deg);
  }
  75% {
    transform: translate(60px, -15px) rotate(0deg);
  }
  85% {
    transform: translate(35px, -50px) rotate(-40deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
.fly3 {
  animation: fly3 1s ease-in-out reverse infinite;
}
@keyframes fly3 {
  0% {
    transform: translate(0px);
    transform: rotate(-60px);
  }
  25% {
    transform: rotate(60px);
    transform: translate(20px, 50px);
  }
  50% {
    transform: rotate(0px);
    transform: translate(40px, 30px);
  }
  65% {
    transform: rotate(180px);
    transform: translate(-10px, -20px);
  }
  75% {
    transform: rotate(360px);
    transform: translate(40px, -80px);
  }
  85% {
    transform: rotate(180px);

    transform: translate(20px, -30px);
  }
  100% {
    transform: rotate(-180px);

    transform: translate(0px);
  }
}
.fly4 {
  animation: fly4 1s ease-in-out reverse infinite;
}
@keyframes fly4 {
  0% {
    transform: rotate(90deg);
    transform: translate(0px);
  }
  25% {
    transform: rotate(180deg);
    transform: translate(-30px, 50px);
  }
  35% {
    transform: rotate(-180deg);
    transform: translate(40px, 30px);
  }
  45% {
    transform: rotate(-360deg);
    transform: translate(20px, 10px);
  }
  75% {
    transform: rotate(-720deg);
    transform: translate(10px, -30px);
  }
  85% {
    transform: rotate(180deg);
    transform: translate(40px, -70px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly5 {
  animation: fly5 1s ease-in-out reverse infinite;
}
@keyframes fly5 {
  0% {
    transform: rotate(-40deg);
    transform: translate(0px);
  }
  15% {
    transform: rotate(-180deg);
    transform: translate(-20px, -80px);
  }
  35% {
    transform: rotate(180deg);
    transform: translate(-60px, -50px);
  }
  55% {
    transform: rotate(-360deg);
    transform: translate(10px, 30px);
  }
  75% {
    transform: rotate(-150deg);
    transform: translate(-80px, -80px);
  }
  90% {
    transform: rotate(80deg);
    transform: translate(10px, -30px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly6 {
  animation: fly6 1s linear reverse infinite;
}
@keyframes fly6 {
  0% {
    transform: rotate(40deg);
    transform: translate(0px);
  }
  15% {
    transform: rotate(200deg);
    transform: translate(-20px, 10px);
  }
  20% {
    transform: rotate(400deg);
    transform: translate(-30px, -50px);
  }
  25% {
    transform: rotate(200deg);
    transform: translate(10px, 0px);
  }
  35% {
    transform: rotate(0deg);
    transform: translate(-60px, -60px);
  }
  45% {
    transform: rotate(-250deg);
    transform: translate(10px, -30px);
  }
  50% {
    transform: rotate(-400deg);
    transform: translate(-10px, 30px);
  }
  55% {
    transform: rotate(-360deg);
    transform: translate(20px, -70px);
  }
  65% {
    transform: rotate(0deg);
    transform: translate(10px, -30px);
  }
  80% {
    transform: rotate(260deg);
    transform: translate(40px, -60px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly7 {
  animation: fly7 1s linear reverse infinite;
}
@keyframes fly7 {
  0% {
    transform: rotate(-60deg);
    transform: translate(0px);
  }
  15% {
    transform: rotate(0deg);
    transform: translate(-20px, -50px);
  }
  20% {
    transform: rotate(230deg);
    transform: translate(0px, -10px);
  }
  25% {
    transform: rotate(-100deg);
    transform: translate(-30px, -0px);
  }
  35% {
    transform: rotate(-390deg);
    transform: translate(-0px, -60px);
  }
  45% {
    transform: rotate(130deg);
    transform: translate(10px, -30px);
  }
  50% {
    transform: rotate(260deg);
    transform: translate(10px, -90px);
  }
  55% {
    transform: rotate(720deg);
    transform: translate(20px, 10px);
  }
  65% {
    transform: rotate(360deg);
    transform: translate(10px, -30px);
  }
  85% {
    transform: rotate(150deg);
    transform: translate(10px, -80px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly8 {
  animation: fly8 1s linear reverse infinite;
}
@keyframes fly8 {
  0% {
    transform: rotate(90deg);
    transform: translate(0px);
  }
  15% {
    transform: rotate(700deg);
    transform: translate(-10px, -50px);
  }
  25% {
    transform: rotate(180deg);
    transform: translate(40px, 0px);
  }
  40% {
    transform: rotate(420deg);
    transform: translate(10px, -30px);
  }
  55% {
    transform: rotate(120deg);
    transform: translate(20px, -70px);
  }
  75% {
    transform: rotate(-20deg);
    transform: translate(10px, -30px);
  }
  85% {
    transform: rotate(-120deg);
    transform: translate(-10px, -80px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly9 {
  animation: fly9 2.2s linear infinite;
}
@keyframes fly9 {
  0% {
    transform: rotate(-40deg);
    transform: translate(-6px);
  }
  20% {
    transform: translate(10px, -120px);
    /* transform: rotate(210deg); */
  }
  40% {
    transform: translate(10px, -30px);
    /* transform: rotate(400deg); */
  }
  55% {
    transform: translate(-40px, -120px);
    /* transform: rotate(400deg); */
  }
  75% {
    transform: translate(80px, -90px);
    transform: rotate(250deg);
  }
  95% {
    transform: translate(-60px, -15px);
    /* transform: rotate(100deg); */
  }
  100% {
    transform: translate(0px);
    transform: rotate(0deg);
  }
}
.fly10 {
  animation: fly10 1s ease reverse infinite;
}
@keyframes fly10 {
  0% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
  15% {
    transform: rotate(180deg);
    transform: translate(-80px, -80px);
  }
  20% {
    transform: rotate(-260deg);
    transform: translate(10px, -20px);
  }
  50% {
    transform: rotate(-350deg);
    transform: translate(-100px, -120px);
  }
  75% {
    transform: rotate(-180deg);
    transform: translate(0px, -100px);
  }
  85% {
    transform: rotate(480deg);
    transform: translate(-60px, -50px);
  }
  95% {
    transform: rotate(280deg);
    transform: translate(-10px, -170px);
  }
  100% {
    transform: rotate(0deg);
    transform: translate(0px);
  }
}
.fly-prize {
  animation: fly-prize 1s linear;
  transform: translateX(0px);
}
@keyframes fly-prize {
  from {
    transform: translateY(-100px);
    /* transform: translateX(0px); */
  }
  25% {
    transform: translateY(0px);
    /* transform: translateX(0px); */
  }
  35% {
    transform: translateY(-100px);
    /* transform: translateX(0px); */
  }
  45% {
    transform: translateY(0px);
  }
  55% {
    transform: translateY(-40px);
  }
  65% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-30px);
  }
  85% {
    transform: translateY(0px);
  }
  95% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.rotate-gat {
  animation: rotate-gat 1s linear forwards;
}
@keyframes rotate-gat {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}
.ani-gift-popup {
  position: relative;
  z-index: 0;
  animation: gift-popup 1s linear forwards;
}
@keyframes gift-popup {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(90%, -300%);
  }
}
.img-gift {
  transform: rotate(15deg);
}
.text-shadow-title {
  text-shadow: 0px 0px 2px #ffffff, 1px 0px 2px #ffffff;
}
.animation-popupgacha {
  animation: fadeGachaPopup 1s linear;
}
@keyframes fadeGachaPopup {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
.animation-press {
  animation: fadePress 0.7s linear forwards;
}
@keyframes fadePress {
  0% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(17px);
  }
}
.animate-gift-gacha {
  animation: fadeGiftGacha 1s linear;
}
@keyframes fadeGiftGacha {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.animation-rotate-giftgacha {
  animation: fadeRotateGiftGacha 0.5s linear alternate-reverse infinite;
}
@keyframes fadeRotateGiftGacha {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}
.border-code-gacha {
  border: 1px solid #ffffff;
  border-radius: 15px;
}
.border-next {
}
