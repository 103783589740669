.rotate-thunder {
  transform: rotate(-0deg);
}
.animation-thunder {
  animation: 1s fadeThunder ease-in infinite alternate-reverse;
}
@keyframes fadeThunder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animation-lightaqua {
  animation: lightAqua 1s linear infinite alternate-reverse;
}
@keyframes lightAqua {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}
/* Tùy chỉnh màu của dấu chấm ba chấm trong Pagination */
.ant-pagination-item-ellipsis {
  color: white !important;
}

/* Đảm bảo các phần tử khác không bị ảnh hưởng */
.ant-pagination-item-ellipsis:hover {
  color: white !important;
}
