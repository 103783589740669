.border-amount-game {
  border: 1px solid #001f7d;
}
.pushable-luckymachine {
  background: #c9681d;
  border-radius: 60px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.pushable-lrluckymachine {
  background: #ff801e;
  border-radius: 60px;
  border: none;
  margin-top: 5px;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front-luckymachine {
  display: block;
  width: 186px;
  height: 48px;
  padding: 6px 12px;
  border-radius: 60px;
  font-size: 1.45rem;
  /* background: hsl(345deg 100% 47%); */
  /* animation: color 3s infinite linear; */
  background: #ff801e;
  color: white;

  transform: translateY(-8px);
}
.front-lrluckymachine {
  display: block;
  width: 46px;
  height: 35px;
  padding: 6px 12px;
  border-radius: 60px;
  font-size: 1.25rem;
  /* background: hsl(345deg 100% 47%); */
  background: #ff801e;
  color: white;

  transform: translateY(-8px);
}
.pushable-lrluckymachine:active .front-lrluckymachine {
  transform: translateY(-3px);
}

.pushable-luckymachine:active .front-luckymachine {
  transform: translateY(-3px);
}
.label-transxluckymachine {
  transform: rotateX(-40deg);
  font-size: 40px;
}
