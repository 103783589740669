.border-item {
  border-radius: 15px;
}
.shadow-item {
  box-shadow: 4px 6px 11px -3px rgba(16, 128, 156, 0.5);
}
.shadow-item-prize {
  box-shadow: 6px -2px 11px -4px rgba(42, 44, 44, 0.5);
  border-top: 2px solid #4e9abb;
}
.border-button-gift {
  border-left: 4px solid #fff6d9;
  color: white;
}
.border-notyet-gift {
  border-left: 4px solid #b5e0ee;
  color: white;
}
.shadow-history {
  box-shadow: -3px 8px 10px -3px rgba(18, 19, 19, 0.5);
}
.border-reward-bill {
  border-top: 2px dotted #eeeeee;
}
