.border-play {
  border-left: 5px solid #b5e0ee;
  border-top-left-radius: 25px;
}
.element {
  width: 100px;
  height: 100px;
  background-color: blue;
}
.fade-background {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 40%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}
.bg-profile-board {
  background-image: linear-gradient(
    rgb(220, 245, 250) 0%,
    rgba(220, 245, 250, 1) 40%,
    rgb(243, 251, 253) 80%,
    rgb(243, 251, 253) 100%
  );
}
.bg-desktop {
  background-image: linear-gradient(
    to right,
    #86ebf8 1%,
    #aaeff8 20%,
    #cff8fd 35%,
    #cff8fd 65%,
    #aaeff8 80%,
    #aaeff8 100%
  );
}
.bg-revive {
  background-image: linear-gradient(
    to right,
    #22bdfa 1%,
    #7ad4f8 20%,
    #79d4f8 35%,
    #79d4f8 65%,
    #7ad4f8 80%,
    #7ad4f8 100%
  );
}
.bg-sting {
  background-image: linear-gradient(
    to right,
    #550c02 1%,
    #5e0e03 20%,
    #4b0a01 35%,
    #971a0a 65%,
    #6d0f02 80%,
    #550c02 100%
  );
}
