.animation-box {
  animation: fadeBox 0.4s linear infinite alternate-reverse;
}
@keyframes fadeBox {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}
.animation-boxstrong {
  animation: fadeBoxStrong 0.1s linear 20 alternate-reverse;
}
@keyframes fadeBoxStrong {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}
.openCap {
  animation: fadeOpenCap 1s ease forwards;
}
@keyframes fadeOpenCap {
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(-100px) translateX(-50%);
  }
}
.animate-showgift {
  animation: fadeOpenGift 2s linear forwards;
}
@keyframes fadeOpenGift {
  0% {
    transform: translateY(0px) translateX(-50%) scale(0);
  }
  100% {
    transform: translateY(50px) translateX(-50%) scale(6);
  }
}
.animate-rotategift {
  animation: rotateGift 10s linear infinite;
}

@keyframes rotateGift {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotatereceive {
  animation: rotateReceive 10s linear infinite;
}

@keyframes rotateReceive {
  0% {
    transform: rotate(0) scale(2);
  }
  100% {
    transform: rotate(360deg) scale(2);
  }
}
.animation-show-luckygift {
  animation: showLuckyGift 1.3s linear forwards;
}
@keyframes showLuckyGift {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.border-luckyBox {
  border: 2px solid #efbf48;
}
