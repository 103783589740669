.border-rotation {
  border: 0px solid hsl(0, 0%, 74%);
}
.border-code {
  border: 1px solid #2d4e68;
}
.border-code-wheel {
  /* border-top: 2px solid #f36e24;
  border-bottom: 2px solid #f36e24;
  border-left: 7px solid #f36e24;
  border-right: 7px solid #f36e24;
  border-radius: 25px; */
  background-color: #f36e24;
}
