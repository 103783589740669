.land-tree {
  background: url(../image/Game/PlantTree/land.png);
  width: 60%;
  background-size: cover;
  background-position: center;
  height: 80px;
}
/* Container for the loading bar and text */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

/* Loading bar background */
.loading-bar {
  width: 280px;
  height: 20px;
  border-radius: 50px;
  background: #096792;
  /* Gradient like the image */
  position: relative;
  overflow: hidden;
  /* Slight border */
}
.border-loading-game {
  border: 2px solid #4fc3f7;
}
/* Bubbles animation */
.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #b3e5fc, #4fc3f7);
  border-radius: 50px;
  animation: moveBubbles 3s forwards alternate linear;
}

/* Loading text */
.loading-text {
  margin-top: 10px;
  font-size: 16px;
  color: #2d4e68; /* Text color similar to the image */
}

/* Keyframes for the bubbles movement */
@keyframes moveBubbles {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
.bg-watering-can {
  background-color: #2d4e68;
  border-radius: 100%;
  border: 2px solid #b5e0ee;
}
.rotation-wateringcan {
  transform: rotate(20deg);
}
.animation-wateringcan {
  animation: fadeWatering 4s linear 1;
  animation-fill-mode: forwards;
}
@keyframes fadeWatering {
  0% {
    transform: translate(0);
  }
  40% {
    transform: translate(-40px, -300px) rotate(-20deg);
  }
  60% {
    transform: translate(-60px, -270px) rotate(-40deg);
  }
  70% {
    transform: translate(-60px, -270px) rotate(-40deg);
  }
  80% {
    transform: translate(-60px, -270px) rotate(-40deg);
  }
  90% {
    transform: translate(-60px, -270px) rotate(-40deg);
  }
  100% {
    transform: translate(0) rotate(10deg);
  }
}
.rotation-water {
  transform: rotate(30deg);
}
.rotation-water-item {
  transform: rotate(-20deg);
}
.animation-waterrun {
  animation: fadeWater 0.6s ease-in-out forwards 3;
}
@keyframes fadeWater {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate(-20px, 70px);
    opacity: 0;
  }
}
.showTab {
  animation: fadeShowTab 1s ease-in-out forwards 1;
}
@keyframes fadeShowTab {
  0% {
    transform: translate(0, 60px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.custom-button {
  background: linear-gradient(to bottom, #c2e8f8 3%, #59c2dd 20%);
  border: none;
  color: white;
  padding: 15px 40px;
  text-align: center;
  font-size: 16px;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.custom-button:hover {
  transform: translateY(0px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-button:active {
  transform: translateY(0);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}
.bg-light-animation {
  background: url(../image/Game/PlantTree/light.png);
  background-size: 90% 100%;
  background-position: center;
  min-width: 120vw;
  height: 400px;
  position: absolute;
  top: -50%;
  left: -65px;
  transform: translateX(-50%);
  animation: 18s fadeRotation linear infinite;
}
@keyframes fadeRotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-profile-tree {
  background-color: #f0fbfe;
}
.animation-seed {
  transform: rotate(-30deg);
}
.animation-tree {
  animation: fadeTree 2s linear 1;
  position: relative;
  /* left: 0; */
  transform-origin: bottom;
}
@keyframes fadeTree {
  0% {
    left: 0;
    transform: rotate(-5deg) scale(0.3);
  }
  25% {
    transform: rotate(5deg) scale(0.4);
  }
  50% {
    transform: rotate(-5deg) scale(0.6);
  }
  75% {
    transform: rotate(5deg) scale(0.8);
  }
  100% {
    left: 0;
    transform: rotate(-5deg) scale(1);
  }
}
.tabImg button:disabled {
  height: 48px;
  border-radius: 20px;
  /* border: 1px solid #98ebff; */
  background-image: url(../../assets/image/Game/PlantTree/btn-tabplant.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  color: white;
  position: relative;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}
.tabImg button {
  height: 40px;
  cursor: pointer;
  text-align: center;
  color: #242e49;
  font-size: 14px;
  border-radius: 20px;
  padding-top: 13px;
  background-size: 100% 100%;
  background-image: url(../../assets/image/Game/PlantTree/btndisable.png);
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.tabImg {
  border-radius: 16px;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}
.animation-olong {
  transform-origin: 50% 0;
  animation: 2.6s fadeRoateBollte linear infinite alternate-reverse;
}
@keyframes fadeRoateBollte {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(14deg);
  }
}
.animation-pepsi {
  transform-origin: 50% 0;
  animation: 1.8s fadeRoateBolltePepsi linear infinite alternate-reverse;
}
@keyframes fadeRoateBolltePepsi {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}
