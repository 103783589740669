.dZaikO {
  padding: 0px;
}

#video {
  position: absolute;
  top: 0px;
}

.btn-webcam {
  height: 80px;
  width: 80px;
  border-radius: 90px;
  margin-top: 4px;
  background-color: #5bc2dc;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.btn-cancel {
  height: 70px;
  border: 0px solid;
  width: 70px;
  border-radius: 18px;
  margin-right: 15px;
  text-align: center;
  margin-top: 10px;
  background-color: #1a9ad1;
  opacity: 1;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 14px;
}

.btn-gui {
  height: 40px;
  border: 0px solid;
  width: 110px;
  border-radius: 18px;
  margin-left: 10px;
  margin-top: 20px;
  background-color: #fee1b9;
  color: #fea32b;
  font-weight: 700;
}

.popup-box-cam {
  /* border-radius: 20px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  /* margin-top: 0px; */
  /* transform: translateY(-28%); */
  padding: 0px 0px 15px 0px;
  /* margin-top: 10px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  background-color: #302d2d;
}

.cam-hd {
  margin-left: 8px;
  font-weight: 400;
  font-size: 19px;
}

.cam-hd1 {
  margin-left: 12px;
  font-weight: 500;
  font-size: 19px;
  border: 1px solid #0367c1;
}
.close-camera {
  position: "absolute";
  opacity: 1;
  top: "30px";
  right: "30px";
  width: "65px";
  height: "65px";
  border-radius: "100px";
  font-size: "18px";
  color: "white";
  background-color: "#81BEB4";
}
.bg-button-camera {
  position: absolute;
  bottom: 0px;
  z-index: 100;
  width: 100%;
  height: 130px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.carousel .control-dots {
  position: relative;
  z-index: -100;
  top: 300px;
  height: 20px;
}
.borderguide {
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  border: 1px solid #dce1e8;
  background: linear-gradient(
    125deg,
    rgba(244, 252, 255, 0.2) 8.85%,
    rgba(244, 252, 255, 0.8) 50.81%
  );
  box-shadow: 0px 4px 4px 0px rgba(104, 199, 223, 0.4);
  backdrop-filter: blur(20px);
}
.border-luuy {
  border: 1px solid #e9e0cf;
}
.bold-text-html b {
  font-weight: 900;
  font-size: 15px;
}
.border-qa {
  border: 2px solid #b5e0ee;
}
#checkhtml div {
  text-align: center;
}
.border-first {
  border: 2px solid #ffffff;
}
.animation_click-takephoto {
  animation: fadeClick 1s linear infinite forwards alternate-reverse;
}
@keyframes fadeClick {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
