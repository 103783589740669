.game-board {
  .card {
    user-select: none;
    // height: 170px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    // margin: 16px;
    padding-top: 106%;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;

    div {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: 0.6s;
    }
    .back {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .front1 {
      transform: rotateY(180deg);
    }
    &.flipped {
      transform: rotateY(180deg);
    }
    &.matched {
      transform: rotateY(180deg);
    }
  }
}
.restart-button {
  width: 12em;
  height: 3em;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 12px;
  background-color: #6d1124;
  border: 0;
}

.centered {
  width: 100%;
  height: 100%;
  text-align: center;
}
