.bg-game-ufo {
  /* background: url(../../assets/fontawesome/image/bg-game.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 120vh;
}
.bg-game-img {
  left: 50%;
  transform: translateX(-50%);
}

.rotate-left {
  transform: rotate(-8deg);
}

.rotate-right {
  transform: rotate(8deg);
}

.rotate-gap {
  transform-origin: 50% 0;
  animation: rotateGap 1.5s ease-in-out infinite;
}

@keyframes rotateGap {
  0%,
  100% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-8deg);
  }
}

.day-gap {
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
}
.day-gap-1 {
  top: -85%;
}
.ani-day-gap {
  top: -85%;
  animation: fadeGap 3.5s linear;
}

@keyframes fadeGap {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  51% {
  }
  100% {
    transform: translateY(0%);
  }
}

.pushable {
  background: hsl(340deg 100% 32%);
  border-radius: 60px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.pushable-lr {
  background: #13839b;
  border-radius: 60px;
  border: none;
  margin-top: 5px;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front {
  display: block;
  width: 76px;
  height: 42px;
  padding: 6px 12px;
  border-radius: 60px;
  font-size: 1.25rem;
  /* background: hsl(345deg 100% 47%); */
  animation: color 3s infinite linear;
  color: white;

  transform: translateY(-8px);
}
.front-lr {
  display: block;
  width: 46px;
  height: 35px;
  padding: 6px 12px;
  border-radius: 60px;
  font-size: 1.25rem;
  /* background: hsl(345deg 100% 47%); */
  background-color: #27c7ea;
  color: white;

  transform: translateY(-8px);
}
.pushable-lr:active .front-lr {
  transform: translateY(-3px);
}

.pushable:active .front {
  transform: translateY(-3px);
}

.label-transx {
  transform: rotateX(-40deg);
  font-size: 40px;
}
.backgr-button-input {
  /* background-image: linear-gradient(
    to right,
    #15a5a6 0%,
    #877691 25%,
    #ee4b7e 50%
  ); */
  margin: 10px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  animation: color 3s infinite linear;
}

@keyframes color {
  0% {
    background: #05f3f3;
  }
  20% {
    background: #059ecc;
  }
  40% {
    background: #0ce6e6;
  }
  60% {
    background: #d3a3f3;
  }
  80% {
    background: #16b9b1;
  }
  100% {
    background: #24e6e6;
  }
}

.ani-tranx-left {
  animation: fadeTranXLeft 0.6s linear;
}

@keyframes fadeTranXLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.ani-tranx-right {
  animation: fadeTranXRight 0.6s linear;
}

@keyframes fadeTranXRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.body-hide {
  overflow: hidden;
}
.animationButton {
  transform-origin: bottom;
  animation: 0.5s fadePlayGame linear forwards;
}
@keyframes fadePlayGame {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}
